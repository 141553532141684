<template>
  <Content
    :current-id="currentId" 
    :init='{ dispatchAction: "pos_sessions", currentId: currentId }'
    :submenu='{ state: true, name: "pos/sessions", currentData: "currentPosSession"}'
    :preloadActions='{ state: true, actions: [{ name: "POS_SESSION", params: currentId }] }'
    :toolbar='{ init: true, name: "products", dispatchAction: "POS_SESSION", currentData: "currentPosSession", currentId: currentId}'
    :key="routeChange"
  />
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { onBeforeRouteUpdate } from "vue-router"
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { useContentStore } from "@/stores/content";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import Content from '@/components/Content.vue';

export default defineComponent({
  components: {
    Content,
  },
  setup(props, {emit}) {
    const route = useRoute();
    const { t } = useI18n();
    const contentStore = useContentStore();

    const routeChange = ref(0);

    const currentId = ref(route.params.uid);

    onMounted(() => {
      MenuComponent.reinitialization();
      contentStore.setBreadcrumbTitle(t('titles.Count') + ' - ' + currentId.value);
    })
    
    onBeforeRouteUpdate((to) => {
      currentId.value = to.params.uid;
      routeChange.value += 1
      contentStore.setBreadcrumbTitle(t('titles.Count') + ' - ' + currentId.value);
    })

    return {
      currentId,
      routeChange
    }
  }
})
</script>